<template>
  <div
    class="badge"
    :class="[
      'badge',
      classes,
      {
        'badge--active': isActiveItem,
        'badge--offer': offer,
        'badge--dark': dark,
        'badge--floating': floating,
        'badge--top-center': topCenter,
        'badge--dense': dense,
        'badge--disabled': disabled || item?.disabled
      }
    ]"
    v-bind="$attrs"
  >
    <slot name="icon">
      <OsIcon v-if="iconProps" v-bind="iconProps" class="badge__icon" />
      <!-- TODO: Use TW classes to set colors -->
      <OsIcon
        v-else-if="isActiveItem && hasDash"
        name="Minus"
        :fill="iconColor"
        class="badge__icon"
        size="12px"
      />
    </slot>
    <span class="badge__text">
      <slot>
        {{ text }}
      </slot>
    </span>
  </div>
</template>

<script lang="ts">
import { IBadgeIcon, Badge } from '~/types/components'

export const BadgeKey: InjectionKey<{ hasDash: boolean }> =
  Symbol.for('BadgeKey')

export default {
  props: {
    item: {
      type: Object as PropType<Badge>,
      required: false,
      default: null
    },
    icon: {
      type: Object as PropType<IBadgeIcon>,
      required: false,
      default: () => ({})
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    offer: {
      type: Boolean,
      required: false,
      default: false
    },
    floating: {
      type: Boolean,
      required: false,
      default: false
    },
    topCenter: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    classes: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup(props) {
    const { osColours } = useUi()
    const text = computed(() => props.item?.text)

    const badgeInternalProps = inject(BadgeKey, { hasDash: true })

    const isActiveItem = computed(() => props.item?.isActive || props.isActive)

    const iconColor = computed(() => {
      if (props.icon?.color?.[0] === '$')
        return osColours[props.icon.color.substring(1)]

      return (
        props.icon?.color ||
        (isActiveItem.value ? osColours.white : osColours.greyDarkest)
      )
    })

    const iconProps = computed(() => {
      const iconName = props.icon?.name || props.item?.icon
      if (!iconName) return

      const iconProps = { ...props.icon }

      return {
        ...iconProps,
        name: iconName,
        fill: iconColor.value,
        width: props.icon?.width?.toString() || '24'
      }
    })

    return {
      iconColor,
      iconProps,
      text,
      isActiveItem,
      hasDash: badgeInternalProps.hasDash
    }
  }
}
</script>

<style lang="scss" scoped>
// this is needed because when the parent is inline-flex the element is aligned based on the first element (which is the icon in this case)
.inline-button .badge__icon {
  min-height: 10px;
}

.badge {
  background: $white;
  color: $black--light;
  min-height: 26px;
  width: -webkit-fit-content;
  width: fit-content;
  min-width: 52px;
  font-size: rem(16);
  line-height: 1;
  font-weight: 100;
  border-radius: 20px;
  padding: 0 rem(8);
  display: flex;
  align-items: center;
  justify-content: center;

  .badge__icon {
    margin-right: rem(4);
  }

  &.badge--dense {
    min-height: 20px;
  }

  &.badge--active {
    background: $blue;
    color: white;
  }

  &.badge--offer {
    background: $green--light;
    color: $grey--darkest;
    font-size: rem(14);
    line-height: rem(15);
    height: rem(18);
    margin-top: 2px;
    min-width: 0;
  }

  &:not(.badge--active).badge--dark {
    background: $grey--lightest;
    color: $black--light;
  }

  &.badge--floating {
    position: absolute;
    z-index: 2;
    top: rem(8);
    left: rem(8);

    &.badge--top-center {
      left: 0;
      right: 0;
      margin: auto;
      white-space: nowrap;
    }
  }

  &.badge--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
